import React from 'react'
import './Education.css'

const Education = () => {
  return (
    <div className='e-container'>
      <div className='full-details'>
      <div className="personal-details">
            <h1>Personal Details</h1>
          <div className='name'>
            <span>Name :</span>
            <span>Chethan.K.M</span>
          </div >
          <div className='name'>
            <span>Date of Birth :</span>
            <span>22/12/1999</span>
          </div>
          <div className='name'>
            <span>Languages Known :</span>
            <span>Kannada,English,Hindi.</span>
          </div>
          <div className='name'>
            <span>Hobbies :</span>
            <span>Tavelling & Playing Volleyball and Cricket.</span>
          </div>
          <div className='name'>
            <span>Permanent Address :</span>
            <span>Ulli layout 2nd cross,15th ward,Ambedkar nagara,Shikaripura,Karnataka 577427.</span>
          </div>
        </div >
        <div className="educational">
        <h1>Educational Details</h1>
           <table>
            
            <thead>
                <tr>
                <th>Qualification</th>
                <th>University/Board</th>
                <th>Institute</th>
                <th>Year of Passing</th>
                <th>Percentage</th>
                </tr>
               
            </thead>
            <tbody>
                <tr>
                
                    <td>BE (ME)</td>
                    <td>Visvesvaraya Technological University </td>
                    <td>Rajarajeswari College of Engineering,Bangalore</td>
                    <td>2021</td>
                    <td>7.65(cgpa)</td>


               </tr>


                 <tr>
                 <td>PUC</td>
                 <td>Department of Pre University Board</td>
                 <td>Sarada Vilas pu college,Mysore</td>
                 <td>2017</td>
                 <td>78%</td>
                 </tr>
               
               <tr>
               <td>10th</td>
               <td>Karnataka secondary education examination board</td>
               <td>Adarsha vidyalaya(r.m.s.a), gundlupet</td>
               <td>2015</td>
               <td>87.20%</td>
               </tr>

            </tbody>
           </table>
        </div>
      </div>
      <div className='education-empty'>

      </div>
       
    </div>
  )
}

export default Education