import React from 'react'
import './Home.css'
import img from './images/chethan.png'
import {BiLogoFacebook} from 'react-icons/bi'
import {BiLogoLinkedin} from 'react-icons/bi'
import {AiFillGithub} from 'react-icons/ai'
import {AiFillInstagram} from 'react-icons/ai'
const Home = () => {
  return (
    <div id='homepages' className='home-container' >
      <div className="left">
        <p>Hello,It's Me</p>
        <p>Chethan K M</p>
        <span className='span1'>And I'm a </span>
        <span className='span2'>Web Developer | Frontend Developer</span>
        <p className='objective'>I'm a web designer haveing a good knowledge on Frontend development ,
        Web-design and Application development.</p>
        <div className='icons'>
          <span> <a href="https://www.facebook.com/profile.php?id=100009120938555&mibextid=ZbWKwL"><BiLogoFacebook className='icon'/></a></span>
          <span> <a href="https://www.linkedin.com/in/chethan-km-b45897159"> <BiLogoLinkedin className="icon" /></a></span>
          <span> <a href="https://github.com/Chethankm4244"> <AiFillGithub className="icon" /></a></span>
          <span> <a href="https://instagram.com/_chethan_k_m?igshid=NGExMmI2YTkyZg=="> <AiFillInstagram className="icon" /></a>
         </span>
        </div>
        <div className='btn1'>
          <a href="#aboutpage"><button>Know More About Me</button></a>
          
        
      </div>
      </div>
     
      <div className="right">
        <div className='first-circle'>
          <div className='second-circle'>
                <div className="image-container">
                  <img src={img} alt="" />
                </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
