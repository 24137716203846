import React from 'react'
import './Header.css'
const Header = () => {
  return (
    <div className='h-container' id='homepage'>
      <div className="h-left">
         <p>
          <font color="orange">P</font>
          <font color="yellow">O</font>
          <font color="#00C1D4">R</font>
          <font color="aqua">T</font>
          <font color="blue">F</font>
          <font color="rgb(248, 9, 212)">O</font>
          <font color="FFCCCC">L</font>
          <font color="WHITE">I</font>
          <font color="red">0</font>
          </p>
      </div>
      <div className="h-right">
        <ul>
            <li><a href="#homepages">Home</a></li>
            <li> <a href="#aboutpage">About</a></li>
            <li><a href="#skillpage">Skills</a></li>
            <li> <a href="#servisepage">Services</a></li>
            <li> <a href="#projectpage">Project</a></li>
            <li><a href="#contactpage">Contact</a></li>
            
        </ul>
      </div>
    </div>
  )
}

export default Header
