import './App.css';
import About from './components/About';
import Header from './components/Header';
import Home from './components/Home';


import Skills from './components/Skills';
import Services from './components/Services';

import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Education from './components/Education';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route path='/' element={
          <>
<Header/>
     <Home/>
     <About/>
     <Skills/>
     <Services/>
     <Projects/>
     <Contact/>
     
          </>
        }>
           
        </Route>
        <Route path='/Education' element={<Education/>}></Route>
      </Routes>
      </BrowserRouter>
     
    
    </div>
  );
}

export default App;
