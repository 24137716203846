import React from 'react'
import './Projects.css'
import project from './images/project.png'
import {Link} from 'react-router-dom'


const Projects = () => {
  return (
    <div className='p-container' id='projectpage'>
      
      <div className='project-heading'>
      <h1>Projects</h1>
       <div className='project'>
        <div className='project-box'>
        <h3>Project Name :</h3>
        <pre > Weather-Application</pre>
        </div>
        
        <span>Deployed Link : </span>
        <Link to="https://weather-report-app.pages.dev/">https://weather-report-app.pages.dev/</Link>
      <div>
        <span>Github Link : </span>
        <a href="https://github.com/Chethankm4244/Weather-application">https://github.com/Chethankm4244/Weather-application </a>
      </div>
       </div>

       <div className='project'>
        <div className='project-box'>
        <h3>Project Name :</h3>
        <pre> Chat-Application</pre>
        </div>
       
        <span>Deployed Link : </span>
        <Link to="http://chat-application-6iz.pages.dev">http://chat-application-6iz.pages.dev</Link>
      <div>
        <span>Github Link : </span>
        <a href="https://github.com/Chethankm4244/chat-application"> https://github.com/Chethankm4244/chat-application</a>
      </div>
       </div>
        
       <div className='project'>
        <div className='project-box'>
        <h3>Project Name : </h3>
        <pre> Ecommerce-Application</pre>
        </div>
        
        <span>Deployed Link : </span>
        <a href="https://myownshopecommerceapp.pages.dev/">https://myownshopecommerceapp.pages.dev/</a>
      {/* <div>
        <span>Github Link : </span>
        <a href=""> </a>
      </div> */}
       </div>

       <div className='project'>
        <div className='project-box'>
        <h3>Project Name : </h3>
        <pre> Expenses Manager</pre>
        </div>
        
        <span>Deployed Link : </span>
        <a href="http://peerxpassignment.pages.dev">http://peerxpassignment.pages.dev</a>
       <div>
        <span>Github Link : </span>
        <a href="https://github.com/Chethankm4244/peerxp-project.io"> https://github.com/Chethankm4244/peerxp-project.io</a>
       </div>
       </div>

       <div className='project'>
        <div className='project-box'>
        <h3>Project Name : </h3>
        <pre> Tic-Tak-Toe Game</pre>
        </div>
        
        {/* <span>Deployed Link : </span>
        <a href="http://peerxpassignment.pages.dev">http://peerxpassignment.pages.dev</a> */}
       <div>
        <span>Github Link : </span>
        <a href="https://github.com/Chethankm4244/Tic-Tok-game.github.io">https://github.com/Chethankm4244/Tic-Tok-game.github.io </a>
       </div>
       </div>

       <div className='project'>
        <div className='project-box'>
        <h3>Project Name :  </h3>
        <pre> Box-Office</pre>
        </div>
{/*        
        <span>Deployed Link : </span>
        <a href="http://peerxpassignment.pages.dev">http://peerxpassignment.pages.dev</a> */}
       <div>
        <span>Github Link : </span>
        <a href="https://github.com/Chethankm4244/box-office.github.io">https://github.com/Chethankm4244/box-office.github.io </a>
       </div>
       </div>
    </div>
    <div>
      <img src={project} alt="" className='project-img' />
    </div>
      </div>
      
  )
}

export default Projects