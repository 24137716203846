import React from 'react'
import './Services.css'
const Services = () => {
  return (
    <div className='service-container' id='servisepage'>
      <div className='service-heading'>
        <span>My</span>
        <span className='skills-name'>  Services</span>
      </div>
      <div className='service-box'>
        <div className='box'>
          <h3>UI / UX Design</h3>
          <p>To use my expertise in UX design to create innovative, user-friendly and intuitive
             products that drive customer engagement.</p>
          

        </div>
        <div className='box'>
          <h3>Web-Design</h3>
          <p>Creating a user-friendly and efficient web-design for your Products and Services to 
            improve in your business and your personal use.</p>
          

        </div>
        <div className='box'>
          <h3>App-Design</h3>
          <p>Creating a application which can be run in any devices like Mobile,Laptop,Tv etc for your products
            and Services in your business.
          </p>

        </div>
        
      </div>
    </div>
  )
}

export default Services