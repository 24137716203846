import React from 'react'
import './About.css'
import web from './images/web.png'
import { Link } from 'react-router-dom'


const About = () => {
  return (
    <div className='a-container' id='aboutpage'>
        <div className='a-left'>
            <img src={web} alt="123" className='web-img' />
        </div>
        <div className='a-right'>
            <div className='myself'>
            <span>About </span>
            <span className='me'>Me</span>
            </div>
        
        <h2>Frontend Developer</h2> <hr />
        <p>I am a skilled web designer with good knowledge in Frontend technologies.My passion lies in  creating captivating website designs and implimenting them through frontend development.I take pride in staying up-to-date with current design trends and creating to produce  
            user friendly websites. Throughout my career,I have development a deep understanding of user experience(UX) and user-interface(UI) principles.
            By putting myself in the shoos of the end-users,I strive to create intuitive seamless browsing experience.I believe that a well-designed 
            websites should not only look visually appealing but also provide a smooth and enjoyable interaction for visitors.
            When starting a new project, I thoroughly research and analyxe the target audience and the clients specific requirements.
            This enables me to tailor my designs to meet their expectations and deliver a unique online presence that aligns with their brand identity. 
        </p>
        <Link to="/education" className='btn3' ><h3>More About Me</h3></Link>
        </div>
       
    </div>
  )
}

export default About