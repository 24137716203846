import React, { useRef } from "react";
import "./Contact.css";
import { ImMail } from "react-icons/im";
import { BsTelephoneForwardFill } from "react-icons/bs";

import { BiLogoFacebook } from "react-icons/bi";
import { BiLogoLinkedin } from "react-icons/bi";
import { AiFillGithub } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
import emailjs from "@emailjs/browser";
import resume from './images/CHETHAN.k.m resume.pdf'

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_fd51nfs",
        "template_mga8k4m",
        form.current,
        "_B58CCn-igAAQRF4g"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <div className="c-container" id="contactpage">

      <div className="top">
      <div className="contact-left">
        <div className="contacts">
        <h1>Contact</h1>
        <h1>Me</h1>
       </div>
       <div className="mail">
        <div className="c-icon">
          <ImMail />
        </div>
        <h2  className="num">
          chethanraj4244@gmail.com
        </h2>
      </div>
      <div className="mail">
        <div className="c-icon">
          <BsTelephoneForwardFill />
        </div>
        <h2 className="num">7090600855</h2>
        <div className="contacticons">
        <span> <a href="https://www.facebook.com/profile.php?id=100009120938555&mibextid=ZbWKwL"><BiLogoFacebook className='icon'/></a></span>
          <span>
            <a href="https://www.linkedin.com/in/chethan-km-b45897159"> <BiLogoLinkedin className="icon" /></a>
           
          </span>
          <span>
            <a href="https://github.com/Chethankm4244"> <AiFillGithub className="icon" /></a>
           
          </span>
          <span> <a href="https://instagram.com/_chethan_k_m?igshid=NGExMmI2YTkyZg=="> <AiFillInstagram className="icon" /></a>
           
          </span>
        </div>
        
      </div>
      <div className="resume-container"><a href={resume} download="resume-pdf" target="_blank" rel="noreferrer"> <button className="resume">
          Click Here to Download Resume
          </button></a></div>
      </div>
      
      <div>
        <form ref={form} onSubmit={sendEmail}>
          
          <input type="text" placeholder="Enter Your Name" name="user_name" />
          <input
            type="email"
            placeholder="Enter Your Email"
            name="user_email"
          />
          <textarea name="message" placeholder="Message" className="messageinput" id="" cols="30" rows="10"></textarea>
          {/* <input type="message" placeholder="Message" name="message" className="messageinput" /> */}
          <button>Send Message</button>
        </form>
      </div>
     
      </div>

      <div className="empty"></div>
    </div>
  );
};

export default Contact;
